import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
// import Features from '../components/Features'
// import BlogRoll from '../components/BlogRoll'
import Banners from '../components/Banners'


export const IndexPageTemplate = ({
  banners,
  mainpitch,
  intro,
}) => (
  <div>
      
    <div className="home-page-intro">
      <p>We are committed to prime quality products and a greener world with sustainable solutions to create higher yields</p>
    </div>
    <div style={{
      maxWidth: '1450px',
      margin: 'auto'
    }}>
      <Banners bannerItems={banners} />
    </div>
    <section className="section section--gradient main-home-content">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    {/* <h1 className="title">{mainpitch.title}</h1> */}
                  </div>
                  <div className="tile" style={{marginBottom: '1em'}}>
                    <p className="subtitle" dangerouslySetInnerHTML={{ __html: mainpitch.description }} />
                  </div>
                    <div className="subtext">
                      <p>{mainpitch.continued}</p>
                    </div>
                </div>
              
                {/* <Features gridItems={intro.blurbs} /> */}
                
                {/* <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest stories
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Read more
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        banners={frontmatter.banners}
        mainpitch={frontmatter.mainpitch}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        banners {
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          link
          bgcolour
        }
        mainpitch {
          title
          description
          continued
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
