import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Section = styled.section`
  &:after {
    background-image: url(${({ background }) => background});
  }
`

const Banners = ({ bannerItems }) => (

  <div>
    {bannerItems.map(item => (
      <div
        key={item.heading}
      className="banner-wrapper"
      style={{
        backgroundColor: `${item.bgcolour}`,
      }}
      >
        <Section background={!!item.image.childImageSharp ? item.image.childImageSharp.fluid.src : item.image}
          className="full-width-image margin-top-0 banner-section"
      style={{
        backgroundColor: `${item.bgcolour}`,
        position: `relative`,
  }}
  >
      <div
        className="container"
      >
        <h1
          // className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
              className="has-text-weight-bold is-size-3-mobile is-size-2-tablet"
          style={{
            color: 'white',
            lineHeight: '1.5',
            fontWeight: 'bold',
            marginBottom: '30px'
          }}
        >
              {item.heading}
              
        </h1>
            <Link to={item.link} className="button is-primary is-inverted is-outlined is-large is-rounded">Products</Link>
      </div>
    </Section>
  </div>
    ))}
  </div>
)


export default Banners
